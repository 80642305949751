<template>
  <div class="admin-devices">
    <div class="toolbar justify-content-between">
      <div>
        <i
          class="icon-button"
          :class="$config.icons.general.back"
          v-b-tooltip
          title="Back to Admin"
          @click="clickBack"
        ></i>
      </div>
      <div>
        <i
          class="icon-button"
          :class="$config.icons.general.refresh"
          v-b-tooltip
          title="Refresh"
          @click="refreshList"
        ></i>
        <div class="icon-composite" @click="$bvModal.show('modal-new-device')">
          <i
            class="icon-composite-main icon-composite-highlight"
            :class="$config.icons.device.base"
            v-b-tooltip.hover
            title="Add New Devices"
          ></i>
          <i
            class="icon-composite-child icon-composite-highlight"
            :class="$config.icons.general.add"
          ></i>
        </div>
        <router-link to="/admin/devices/bulkadd">
          <i
            class="mx-1 icon-button"
            :class="$config.icons.device.bulkAdd"
            v-b-tooltip.hover
            title="Bulk Add Devices"
          ></i>
        </router-link>
        <div class="icon-composite" @click="deleteSelected">
          <i
            class="mx-1 icon-composite-main icon-composite-highlight"
            :class="$config.icons.device.base"
            v-b-tooltip.hover
            title="Remove Selected Devices"
          ></i>
          <i
            class="mx-1 icon-composite-child icon-composite-highlight"
            :class="$config.icons.general.remove"
          ></i>
        </div>
        <i class="icon-button" :class="$config.icons.general.export" v-b-tooltip title="Export" @click="exportDeviceList"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Device Admin</h2></b-card-header>
        <b-card-body>
          <admin-device-list
            v-on:change="selectChange"
            ref="adminDeviceList"
            :show-fields="deviceFields"
          ></admin-device-list>
        </b-card-body>
      </b-card>
    </div>
    <!--   Device Edit Modal Template-->
    <b-modal
      id="modal-new-device"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Add Device"
    >
      <admin-edit-device
        is-modal
        modal="modal-new-device"
        v-on:save="onSave()"
      ></admin-edit-device>
    </b-modal>

    <b-modal id="modal-export" centered class="modal-content" size="xl" hide-footer title="Export Data">
      {{exportStatus}}}
      <b-progress  :max="dataPages" class="w-100">
        <b-progress-bar :value="currentPage" :animated="!downloadComplete">
        </b-progress-bar>
      </b-progress>
      <data-exporter v-if="downloadComplete" :data="deviceExportData" ></data-exporter>
    </b-modal>
  </div>
</template>

<script>
import AdminDeviceList from '../../components/admin/AdminDeviceList'
import AdminEditDevice from '../../components/admin/AdminEditDevice'
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
import * as DataProvider from '../../components/helpers/DataProvider'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import DataExporter from '@/components/DataExporter.vue'

export default {
  name: 'admin-devices',
  components: {
    DataExporter,
    AdminDeviceList,
    AdminEditDevice
  },
  data: function () {
    return {
      selectedDevices: [],
      deviceFields: [
        'selected', 'device_type', 'device_imei',
        'device_code', 'device_name', 'location.datetime',
        'billable', 'has_fuel_data', 'has_subscription',
        'actions'],
      downloadComplete: false,
      deviceExportData: [],
      currentPage: 1,
      dataPages: 0,
      exportStatus: ''
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    refreshList: function () {
      this.$refs.adminDeviceList.refreshDeviceList()
    },
    selectChange: function (newSelection) {
      this.selectedDevices = newSelection
    },
    deleteSelected: async function () {
      if (this.selectedDevices.length === 0) {
        ErrorHelper.displayGeneralErrorToast(
          "You don't have ay users selected to delete!",
          'No Users Selected'
        )
        return
      }

      let res = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete ${this.selectedDevices.length} Devices?`,
        {
          title: 'Confirm Delete',
          okVariant: 'danger',
          centered: true
        }
      )
      if (res) {
        for (let device of this.selectedDevices) {
          console.log('Archiving: ', device)
          let response = await DataProvider.adminArchiveDevice(device.device_imei)
          if (response.success) {
            AlertHelper.successToast('The Device was successfully deleted.', 'Delete Successful')
          } else {
            ErrorHelper.displayDataErrorToast(response)
          }
        }
        this.$refs.adminDeviceList.refreshDeviceList()
      }
    },
    onSave: function (data) {
      console.log('Save event detected')
      console.log({ data })
      this.refreshList()
    },
    async exportDeviceList() {
      this.$bvModal.show('modal-export')
      let deviceTotalData = await DataProvider.getAdminDeviceCount(false)
      console.log(deviceTotalData)
      this.exportStatus = 'Downloading Device Data...'
      let perPage = 50
      this.dataPages = Math.ceil(deviceTotalData.data.number_of_devices / perPage)
      await this.tickFetchData()
    },
    async tickFetchData() {
      this.$nextTick(() => {
        this.getDevicePage(this.currentPage)
      })
    },
    async getDevicePage(page, perPage = 50) {
      let resp = await DataProvider.getAdminDeviceListPaginated({
        start: (page - 1) * perPage,
        page_size: perPage,
        archived: false
      })
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        this.currentPage += 1
        this.deviceExportData = this.deviceExportData.concat(resp.data.devices)
        console.log(this.deviceExportData)
        if (this.currentPage <= this.dataPages) {
          this.tickFetchData()
        } else {
          this.exportStatus = 'Download Complete!'
          this.downloadComplete = true
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.admin-devices {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  overflow: auto;
}

.list-containter {
  background: $theme-color-background-4;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 3rem;
}

.icon-composite {
  display: contents;
}

.icon-composite-main {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 5px;
  background-color: transparent;
  color: $theme-color-primary-5;
  text-align: center;
  cursor: pointer;
}

.icon-composite-child {
  position: relative;
  font-size: 1.8rem;
  left: -1.8rem;
  top: -1.2rem;
  background-color: transparent;
  color: $theme-color-primary-2;
  text-align: center;
  cursor: pointer;
}

.icon-composite:focus,
.icon-composite:hover .icon-composite-highlight {
  color: $theme-color-primary-1 !important;
  //border-color: $theme-color-primary-3;
  //background: $theme-color-secondary-2;
  text-shadow: 0px 0px 20px $theme-color-secondary-2,
    0px 0px 10px $theme-color-secondary-2;
}
</style>
