var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-devices" },
    [
      _c("div", { staticClass: "toolbar justify-content-between" }, [
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.back,
            attrs: { title: "Back to Admin" },
            on: { click: _vm.clickBack }
          })
        ]),
        _c(
          "div",
          [
            _c("i", {
              directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
              staticClass: "icon-button",
              class: _vm.$config.icons.general.refresh,
              attrs: { title: "Refresh" },
              on: { click: _vm.refreshList }
            }),
            _c(
              "div",
              {
                staticClass: "icon-composite",
                on: {
                  click: function($event) {
                    return _vm.$bvModal.show("modal-new-device")
                  }
                }
              },
              [
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "icon-composite-main icon-composite-highlight",
                  class: _vm.$config.icons.device.base,
                  attrs: { title: "Add New Devices" }
                }),
                _c("i", {
                  staticClass: "icon-composite-child icon-composite-highlight",
                  class: _vm.$config.icons.general.add
                })
              ]
            ),
            _c("router-link", { attrs: { to: "/admin/devices/bulkadd" } }, [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "mx-1 icon-button",
                class: _vm.$config.icons.device.bulkAdd,
                attrs: { title: "Bulk Add Devices" }
              })
            ]),
            _c(
              "div",
              {
                staticClass: "icon-composite",
                on: { click: _vm.deleteSelected }
              },
              [
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass:
                    "mx-1 icon-composite-main icon-composite-highlight",
                  class: _vm.$config.icons.device.base,
                  attrs: { title: "Remove Selected Devices" }
                }),
                _c("i", {
                  staticClass:
                    "mx-1 icon-composite-child icon-composite-highlight",
                  class: _vm.$config.icons.general.remove
                })
              ]
            ),
            _c("i", {
              directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
              staticClass: "icon-button",
              class: _vm.$config.icons.general.export,
              attrs: { title: "Export" },
              on: { click: _vm.exportDeviceList }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "list-containter" },
        [
          _c(
            "b-card",
            [
              _c("b-card-header", [_c("h2", [_vm._v("Device Admin")])]),
              _c(
                "b-card-body",
                [
                  _c("admin-device-list", {
                    ref: "adminDeviceList",
                    attrs: { "show-fields": _vm.deviceFields },
                    on: { change: _vm.selectChange }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-new-device",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Add Device"
          }
        },
        [
          _c("admin-edit-device", {
            attrs: { "is-modal": "", modal: "modal-new-device" },
            on: {
              save: function($event) {
                return _vm.onSave()
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-export",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Export Data"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.exportStatus) + "} "),
          _c(
            "b-progress",
            { staticClass: "w-100", attrs: { max: _vm.dataPages } },
            [
              _c("b-progress-bar", {
                attrs: {
                  value: _vm.currentPage,
                  animated: !_vm.downloadComplete
                }
              })
            ],
            1
          ),
          _vm.downloadComplete
            ? _c("data-exporter", { attrs: { data: _vm.deviceExportData } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }